@import url('https://fonts.googleapis.com/css2?family=Michroma&display=swap');

.nav-title {
  font-family: 'Michroma', sans-serif;
  font-size: 30px;
}

.plan-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: minmax(100px, auto);
}

.plan-item {
  border-radius: 5px;
  border: 1px solid rgb(235, 235, 234);
  margin: 10px;
}

.plan-content {
  padding: 10px;
}

.mdu-btn {
  border: 1px solid #009c00;
  color: #009c00;
  background-color: transparent;
  padding: 7px;
  border-radius: 5px;
  width: 175px;
}

.plan-btn {
  border: 1px solid #009c00;
  color: #009c00;
  background-color: transparent;
  padding: 7px;
  border-radius: 5px;
  min-width: 175px;
}

.plan-btn:hover {
  background-color: #009c00;
  color: white;
}

.plan-btn:disabled {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

.next-btn {
  border: 1px solid #009c00;
  background-color: #009c00;
  padding: 7px;
  border-radius: 5px;
  width: 400px;
  color: white;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px;
}

.cancel-btn {
  border: 1px solid red;
  color: red;
  background-color: transparent;
  padding: 7px;
  border-radius: 5px;
  width: 175px;
}

.cancel-btn:hover {
  background-color: red;
  color: white;
}

.loader-container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: rgba(0, 0, 0, 0.1);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border-left-color: #009c00;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.btn_ctn {
  position: fixed;
  bottom: 15px;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}
